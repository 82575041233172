import React, { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import {
	ActionIcon,
	Alert,
	Box,
	Button,
	createStyles,
	Flex,
	Icon,
	Modal,
	Stack,
	TFText,
	Tooltip,
} from '@tf/ui';
import { fmt } from '@tf/utils';

import DownloadLink from './DownloadLink';

interface Props {
	file: BackendTypes.FieldFile;
	onDelete: (file: BackendTypes.FieldFile) => Promise<void>;
	isReadOnly?: boolean;
}

const useStyles = createStyles(({ colors, radius }) => ({
	wrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.25rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	content: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
	},
}));

const DocumentItem: React.FC<Props> = ({ file, isReadOnly, onDelete }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { classes } = useStyles();

	const deleteHandler = () => {
		setIsOpen(false);
		onDelete(file);
	};

	return (
		<>
			<Box className={classes.wrapper}>
				<Box className={classes.content}>
					<DownloadLink file={file} />
					<Flex>
						{file.uploadDate && (
							<TFText size="xs" color="dimmed">
								{fmt.toDate(file.uploadDate, { preset: 'full_date_with_time' })}
							</TFText>
						)}
						{file.size && (
							<TFText ml="8px" size="xs" color="dimmed">
								{fmt.toFileSize(file.size)}
							</TFText>
						)}
					</Flex>
				</Box>

				<Box hidden={isReadOnly}>
					<Tooltip label="Delete document" withArrow>
						<ActionIcon variant="subtle" color="red" onClick={() => setIsOpen(true)} hidden={isReadOnly}>
							<Icon.IconTrash size={18} stroke={1.5} />
						</ActionIcon>
					</Tooltip>
				</Box>
			</Box>

			<Modal centered opened={isOpen} onClose={() => setIsOpen(false)} title="Delete confirmation">
				<Stack spacing={9} p=".5rem 1rem">
					<TFText size="sm">Are you sure you want to delete this document?</TFText>
					<Alert color="gray" p=".25rem 1rem">
						<TFText weight={500}>{file.filename}</TFText>
					</Alert>
					<Stack spacing={9} mt="1rem">
						<Button
							color="red"
							leftIcon={<Icon.IconTrash size={18} stroke={1.8} />}
							fullWidth
							onClick={deleteHandler}
						>
							Delete Document
						</Button>
						<Button fullWidth variant="light" onClick={() => setIsOpen(false)}>
							Cancel
						</Button>
					</Stack>
				</Stack>
			</Modal>
		</>
	);
};

export default DocumentItem;
