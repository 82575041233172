import React from 'react';

import type { ModalBaseStylesNames, ModalProps, Styles } from '@mantine/core';
import { Button, createStyles, Group, Modal } from '@mantine/core';
import constate from 'constate';

const [ModalControlProvider, useModalControlState] = constate(
	(modalControl: { onClose: ModalProps['onClose'] }) => {
		return modalControl;
	}
);

export const useModalControl = useModalControlState;

export function createModal<ContentProps extends object>(
	ModalContent: React.ComponentType<ContentProps>
) {
	const FullModal: React.FC<Omit<ModalProps, 'children'> & { contentProps?: ContentProps | null }> = ({
		contentProps = null,
		...modalProps
	}) => {
		return (
			<Modal
				styles={modalProps?.fullScreen ? fullscreenModalStyles : regularModalStyles}
				radius="sm"
				{...modalProps}
			>
				<ModalControlProvider onClose={modalProps.onClose}>
					{/* NOTE: there is no check that contentProps is not null or undefined when modal opened, we rely on runtime errors during development */}
					{modalProps.opened ? <ModalContent {...(contentProps as ContentProps)} /> : null}
				</ModalControlProvider>
			</Modal>
		);
	};

	FullModal.displayName = `createModal(${ModalContent.displayName ?? ModalContent.name})`;

	return FullModal;
}

interface ModalFooterProps {
	onCancel?: () => void;
	onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
	isDisabled?: boolean;
	isLoading?: boolean;
	cancelLabel?: string;
	submitLabel?: string;
	submitButtonColor?: 'red';
	fullScreen?: boolean;
}

const useStyles = createStyles(({ colors }) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '.5rem 0',
		padding: '.75rem .75rem .25rem',
		borderTopStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: colors.light[2],
	},
	button: {
		padding: '0 54px',
		flex: 1,
	},
}));

// TODO: must be fixed on bottom
//	see https://github.com/orgs/mantinedev/discussions/1141#discussioncomment-7195745
//	Ask Anton first
export const ModalFooter: React.FC<ModalFooterProps> = ({
	onCancel,
	cancelLabel,
	isDisabled,
	isLoading,
	onSubmit,
	submitLabel,
	submitButtonColor,
	fullScreen,
}) => {
	const { classes } = useStyles();

	return (
		<Group className={classes.root} spacing=".75rem">
			{onCancel ? (
				<Button
					variant="light"
					sx={{ flex: fullScreen ? 0 : 1 }}
					className={classes.button}
					onClick={onCancel}
				>
					{cancelLabel || 'Cancel'}
				</Button>
			) : null}

			<Button
				className={classes.button}
				sx={{ flex: fullScreen ? 0 : 1 }}
				loading={isLoading}
				variant="filled"
				color={submitButtonColor}
				loaderProps={{ size: 14 }}
				disabled={isDisabled || isLoading}
				{...(onSubmit ? { onClick: onSubmit } : { type: 'submit' })}
			>
				{submitLabel || 'Submit'}
			</Button>
		</Group>
	);
};

const regularModalStyles: Styles<ModalBaseStylesNames> = {
	header: {
		display: 'flex',
		justifyContent: 'center',
		padding: '.75rem 1rem',
		margin: 0,
		borderWidth: '0 0 1px',
		borderStyle: 'solid',
		borderColor: '#EAECF0',
	},
	title: {
		fontSize: '1.2rem',
		fontWeight: 500,
	},
	body: { padding: 0 },
};

const fullscreenModalStyles: Styles<ModalBaseStylesNames> = {
	header: {
		border: 'none',
		flexDirection: 'column-reverse',
		background: '#F8FAFC',
		position: 'static',
	},
	title: {
		flex: 1,
		fontSize: '26px',
		fontWeight: 500,
		marginTop: '44px',
		textAlign: 'center',
	},
	body: {
		padding: 0,
		width: '600px',
		margin: '0 auto',
		backgroundColor: '#F8FAFC',
		borderRadius: '8px',
		paddingBottom: '40px',
	},
	content: {
		background: '#F8FAFC',
	},
};
