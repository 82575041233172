import React from 'react';

import type { CenterProps, LoaderProps } from '@mantine/core';
import { Center, Loader, Stack, Text } from '@mantine/core';

interface Props extends Omit<CenterProps, 'children'> {
	label?: string;
	size?: LoaderProps['size'];
}

const LoadingOverlay: React.FC<Props> = ({ label, size, ...props }) => {
	return (
		<Center h="100vh" {...props}>
			<Stack spacing={12} align={'center'}>
				<Loader size={size} />
				{label && <Text color={'dimmed'}>{label}</Text>}
			</Stack>
		</Center>
	);
};

export default LoadingOverlay;
