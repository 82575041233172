import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Group, Input, Select, TextInput } from '@tf/ui';

import { useConfigContext, useFormStore, useSegmentContext } from '../../../hooks';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export default function BirthPlaceInput({
	name,
	label,
	isReadOnly,
	isRequired,
	tooltip,
}: DefaultInputProps) {
	const { register, control } = useFormContext();
	const formStore = useFormStore((s) => s);
	const { identity } = useSegmentContext();

	const isRequiredCountry = formStore
		.getFieldValidationRules(
			identity.segmentKind,
			`${name.replace(identity.segmentKind + '.', '')}.country`
		)
		.some((rule) => rule.rule === 'required');
	const isRequiredCity = formStore
		.getFieldValidationRules(
			identity.segmentKind,
			`${name.replace(identity.segmentKind + '.', '')}.city`
		)
		.some((rule) => rule.rule === 'required');

	// * Countries options
	const defs = useConfigContext((s) => s);
	const options = defs.getEnumOptions('common__enum__country') || [];

	return (
		<>
			<Input.Label required={isRequired}>{label}</Input.Label>
			<Group grow spacing={12}>
				<Controller
					control={control}
					name={`${name}.country`}
					render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
						<Input.Wrapper label="Country" labelProps={{ required: isRequiredCountry }}>
							<Select
								id={`${name}.country`}
								ref={ref}
								value={value}
								onChange={onChange}
								searchable={true}
								onBlur={onBlur}
								placeholder={isReadOnly ? 'Country not specified' : 'Select country'}
								readOnly={isReadOnly}
								data-readonly={isReadOnly}
								data={options}
								error={Boolean(error)}
								rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
							/>
						</Input.Wrapper>
					)}
				/>
				<Controller
					control={control}
					name={`${name}.city`}
					render={({ fieldState: { error } }) => (
						<Input.Wrapper label="City" labelProps={{ required: isRequiredCity }}>
							<TextInput
								id={`${name}.city`}
								error={Boolean(error)}
								readOnly={isReadOnly}
								rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
								{...register(`${name}.city`)}
							/>
						</Input.Wrapper>
					)}
				/>
			</Group>
		</>
	);
}
