import React from 'react';

import { Anchor, Box, Center, createStyles, Divider, Stack, TFText, Title } from '@tf/ui';
import { fmt } from '@tf/utils';

const useStyles = createStyles(({ colors }) => ({
	row: {
		margin: '0  0 .5rem',
		padding: '.5rem 0',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '.75rem',
	},
	border: {
		borderColor: colors.light[2],
	},
}));

interface Props {
	adverseMedia: any;
}

export const AdverseMedia: React.FC<Props> = ({ adverseMedia }) => {
	const { classes } = useStyles();
	if (!adverseMedia) {
		return null;
	}

	const hasData = Array.isArray(adverseMedia) && adverseMedia.length > 0;

	return (
		<Box>
			{hasData ? (
				<>
					{adverseMedia.map((item: any, index: number) => {
						const key = `${item.name}-${index}`;

						return (
							<Box key={key} className={classes.row}>
								<Box className={classes.header}>
									<Anchor
										target="_blank"
										rel="noreferrer"
										href={item.url}
										lineClamp={2}
										sx={{ flex: 1, marginRight: '1rem' }}
									>
										<Title order={5}>{item.title}</Title>
									</Anchor>
									<TFText size="sm" color="dimmed">
										{item.date ? fmt.toDate(item.date, { preset: 'full_date' }) : 'N/A'}
									</TFText>
								</Box>

								<TFText size="sm" lineClamp={5}>
									{item.snippet ? item.snippet : 'N/A'}
								</TFText>
								{index !== adverseMedia.length - 1 && <Divider className={classes.border} my="sm" />}
							</Box>
						);
					})}
				</>
			) : (
				<Center h={300}>
					<Stack align="center" spacing="sm">
						<Title order={4} color="dimmed">
							No adverse media found
						</Title>
					</Stack>
				</Center>
			)}
		</Box>
	);
};
