import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Group, Input, NumberInput, type NumberInputProps } from '@tf/ui';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

interface Period {
	start: number;
	end: number;
}

export default function DatePeriodInput({ name, label, isReadOnly, tooltip }: DefaultInputProps) {
	const form = useFormContext();

	const [value, setValue] = useState<Period>({
		start: form.getValues(`${name}.start`) ?? 0,
		end: form.getValues(`${name}.end`) ?? 0,
	});

	useEffect(() => {
		form.setValue(name, value, { shouldDirty: true });
	}, [value]);

	const inputProps: NumberInputProps = {
		readOnly: isReadOnly,
		max: 2100,
		min: 1900,
		maxLength: 4,
		hideControls: true,
	};

	return (
		<>
			<Input.Label>{label}</Input.Label>
			<Group grow spacing={12}>
				<NumberInput
					id={`${name}-start`}
					value={value.start}
					placeholder="Start Year"
					readOnly={isReadOnly}
					rightSection={<TooltipIcon tooltip={tooltip} />}
					onChange={(value) => {
						if (value) {
							setValue((state) => ({ ...state, start: value ?? 0 }));
						}
					}}
					{...inputProps}
				/>
				<NumberInput
					id={`${name}-end`}
					value={value.end}
					placeholder="End Year"
					readOnly={isReadOnly}
					rightSection={<TooltipIcon tooltip={tooltip} />}
					onChange={(value) => {
						if (value) {
							setValue((state) => ({ ...state, end: value ?? 0 }));
						}
					}}
					{...inputProps}
				/>
			</Group>
		</>
	);
}
