import React from 'react';

import { Alert, Box, createStyles, Group, Icon, Stack, TFCard, TFText } from '@tf/ui';
import type { FieldDefinition } from '@tf/utils';
import { RuleType, S } from '@tf/utils';

import { useConfigContext, useFormStore, useSegmentContext } from '../../hooks';
import { CURRENCY_SYMBOLS, FIELD_MODIFIERS } from '../../utils';
import { StructField } from '../StructField';

import GenericField from './GenericField';
import {
	AddressInput,
	BackgroundCheck,
	BirthPlaceInput,
	CheckboxInput,
	CRACalculationJobInfo,
	DateInput,
	DateMonthYearInput,
	DatePeriodInput,
	ExperienceInput,
	FileInput,
	InfoField,
	LabelField,
	LivenessCheck,
	MoneyInput,
	MultiSelectInput,
	NumberInput,
	PercentageInput,
	PhoneInput,
	SelectInput,
	TextareaInput,
	TextInput,
} from './inputs';
import { TooltipIcon } from './TooltipIcon';
import type { DefaultInputProps, FieldProps } from './types';

const useStyles = createStyles(({ fontSizes, colors, radius }) => ({
	structTitle: {
		fontWeight: 500,
		fontSize: '.85rem',
		padding: '.375rem .5rem',
		borderWidth: '1px',
		borderTopLeftRadius: radius.sm,
		borderTopRightRadius: radius.sm,
		borderStyle: 'solid',
		borderColor: colors.brand[1],
		backgroundColor: '#eef4ff',
	},
	structContent: {
		borderWidth: '0 1px 1px',
		borderStyle: 'solid',
		borderColor: colors.gray[2],
		padding: '.5rem .675rem .875rem',
		borderBottomLeftRadius: radius.sm,
		borderBottomRightRadius: radius.sm,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '.5rem',
	},
	title: {
		color: colors.gray[4],
		fontWeight: 600,
		fontSize: fontSizes.xs,
		letterSpacing: '.5px',
		cursor: 'default',
		userSelect: 'none',
	},
}));

const Field: React.FC<FieldProps> = ({ def }) => {
	const { classes } = useStyles();
	const { getStructDefinition, modifiers, currency } = useConfigContext((s) => s);
	const form = useFormStore((s) => s);

	// * Consume segment context
	const { identity } = useSegmentContext();
	// * Base input props
	const fieldProps: DefaultInputProps = {
		name: `${identity.segmentKind}.${def.name}`,
		label: def.visual.label,
		placeholder: def.visual.placeholder,
		isReadOnly: def.isReadOnly,
		tooltip: def.visual.tooltip,
	};

	// TODO: make a univocal way to remove a required property
	if (!(modifiers?.type === 'visible' && modifiers?.value === 'removeRequired')) {
		fieldProps.isRequired = !def.isOptional;
	}

	// @ts-ignore
	const preset = def.visual.preset || '';

	if (preset === FIELD_MODIFIERS.MONEY_LABEL) {
		fieldProps.label = fieldProps.label?.replaceAll(
			'$CURRENCY_SYMBOL',
			currency ? CURRENCY_SYMBOLS[currency] : ''
		);
	}

	switch (def.type) {
		case 'info': {
			return <InfoField label={def.visual.label} />;
		}
		case 'label': {
			return <LabelField label={def.visual.label} />;
		}
		case 'text': {
			if (preset === FIELD_MODIFIERS.TEXTAREA) {
				return <TextareaInput {...fieldProps} />;
			}
			if (preset === FIELD_MODIFIERS.DATE) {
				return <DateInput {...fieldProps} />;
			}
			return <TextInput {...fieldProps} />;
		}
		case 'phone': {
			return <PhoneInput {...fieldProps} />;
		}
		case 'money': {
			return <NumberInput {...fieldProps} currency={currency} />;
		}
		case 'email': {
			return <TextInput {...fieldProps} />;
		}
		case 'date':
			if (preset === FIELD_MODIFIERS.DATE_MONTH_YEAR) {
				return <DateMonthYearInput {...fieldProps} />;
			}

			return <DateInput {...fieldProps} />;
		case 'number':
			if (preset === FIELD_MODIFIERS.PERCENTAGE) {
				return <PercentageInput {...fieldProps} />;
			}
			return <NumberInput {...fieldProps} />;
		case 'boolean':
			return <CheckboxInput {...fieldProps} />;
		case 'enum':
			if (preset === FIELD_MODIFIERS.MONEY_SELECT) {
				return <SelectInput {...fieldProps} enumName={def.ref} currency={currency} />;
			}
			if (def.isMultiple) {
				return <MultiSelectInput {...fieldProps} enumName={def.ref} />;
			}
			return <SelectInput {...fieldProps} enumName={def.ref} />;
		case 'struct': {
			if (preset === FIELD_MODIFIERS.BACKGROUND_CHECK) {
				// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
				return <BackgroundCheck {...fieldProps} />;
			}

			// TODO: should check by ref, not preset - we always want this special component for ref = LivenessCheckJobInfo
			if (preset === FIELD_MODIFIERS.LIVENESS_CHECK) {
				return <LivenessCheck {...fieldProps} />;
			}

			if (def.ref === 'CRACalculationJobInfo') {
				return <CRACalculationJobInfo {...fieldProps} />;
			}

			// TODO: change to 'invisible always'
			if (preset === FIELD_MODIFIERS.DOCUMENTS_CHECK) {
				return null;
			}

			const ref = def.ref as string;

			if (!ref) {
				return null;
			}

			if (preset === FIELD_MODIFIERS.DATE_PERIOD) {
				return <DatePeriodInput {...fieldProps} />;
			}
			if (preset === FIELD_MODIFIERS.PERCENTAGE) {
				return <PercentageInput {...fieldProps} />;
			}
			if (preset === FIELD_MODIFIERS.BIRTH_PLACE) {
				return <BirthPlaceInput {...fieldProps} />;
			}
			if (preset === FIELD_MODIFIERS.MONEY) {
				return <MoneyInput {...fieldProps} />;
			}

			const structDef = getStructDefinition(ref);
			if (!structDef || !structDef.fields || structDef.fields.length === 0) {
				return null;
			}

			if (preset === FIELD_MODIFIERS.ADDRESS) {
				return (
					<AddressInput
						structDef={structDef}
						title={fieldProps.label}
						name={fieldProps.name}
						tooltip={fieldProps.tooltip}
					/>
				);
			}

			if (structDef.name === 'common__knowledge_and_experience') {
				return (
					<Box>
						<Group className={classes.structTitle} spacing={9}>
							<Icon.IconBox size={16} color="#155EEF" />
							<TFText mt={1}>{def.visual.label || S.prettify(def.name)}</TFText>
						</Group>
						<ExperienceInput
							isReadonly={def.isReadOnly}
							structDef={structDef}
							segmentName={fieldProps.name}
							className={classes.structContent}
						/>
					</Box>
				);
			}

			if (form.hiddenFields.includes(structDef.name)) {
				return null;
			}

			const structFields = Object.values(structDef.fields).filter(
				(field) => !form.hiddenFields.includes(field.name)
			);

			if (structDef.kind === 'document') {
				const fields = Object.values(structDef.fields);

				const structFields: FieldDefinition[] = fields
					.filter((f) => !form.hiddenFields.includes(f.name))
					.map((f) => ({
						...f,
						name: `${def.name}.${f.name}` as const,
					}));

				// Required fields in documents: files, is_no_document, reason
				// Everything else is additional fields, and we show them in a TFCard
				if (fields.length <= 3) {
					const extensions: string[] = [];
					if (structFields.some((field) => field.name === 'document_check_job')) {
						extensions.push('DOCUMENT_CHECK');
					}
					return <FileInput {...fieldProps} fieldPath={def.name} extensions={extensions} />;
				}

				const refs = Object.values(structDef.fields).map((field) => field.ref);

				const isRequiredFile = form
					.getFieldValidationRules(identity.segmentKind, def.name)
					.some((rule) => rule.rule === 'required' && rule.type === RuleType.DOCUMENT);

				return (
					<TFCard
						title={
							def.visual.tooltip ? (
								<Box className={classes.header}>
									<TFText className={classes.title}>{fieldProps.label?.toUpperCase()}</TFText>
									<TooltipIcon style={{ marginLeft: '4px' }} tooltip={def.visual.tooltip} />
								</Box>
							) : (
								fieldProps.label
							)
						}
					>
						<Box p="0.75rem 1rem">
							<StructField
								structFields={structFields}
								def={def}
								hasFile={true}
								identity={identity}
								isReadOnly={fieldProps.isReadOnly as boolean}
								isRequiredFile={isRequiredFile}
								visualRules={form.getFieldVisualRules(identity.segmentKind, `${def.name}`)}
								refs={refs}
							/>
						</Box>
					</TFCard>
				);
			}

			return (
				<Box>
					<Group className={classes.structTitle} spacing={9}>
						<Icon.IconBox size={16} color="#155EEF" />
						<TFText mt={1}>{def.visual.label || S.prettify(def.name)}</TFText>
						<TooltipIcon tooltip={def.visual.tooltip} />
					</Group>
					<Stack spacing={9} className={classes.structContent}>
						{structFields.map((fieldDef) => {
							return <GenericField key={fieldDef.name} def={fieldDef} path={def.name} />;
						})}
					</Stack>
				</Box>
			);
		}
		default:
			return <Alert color="red">Unknown field type: {def.type}</Alert>;
	}
};

export default Field;
