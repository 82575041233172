import React from 'react';

import type { BackendTypes } from '@tf/api';
import { createStyles, Stack, Table } from '@tf/ui';

import { useConfigContext } from '../../../hooks';

import { CheckboxInput, NumberInput } from './index';

const useStyles = createStyles(({ colors }) => ({
	tableStack: {
		padding: 0,
	},
	tableStyles: {
		'& th, & td': {
			borderColor: `${colors.gray[2]} !important`,
		},
		'& td:first-of-type': {
			minWidth: '180px',
		},
	},
	infoField: {
		'> span': {
			fontWeight: 300,
		},
	},
}));

const ROW_NAME = 'Financial instrument';

interface ExperienceInputProps {
	structDef: BackendTypes.StructDefinition;
	segmentName: string;
	className: string;
	isReadonly?: boolean;
}

export const ExperienceInput: React.FC<ExperienceInputProps> = ({
	structDef,
	className,
	segmentName,
	isReadonly,
}) => {
	const { getStructDefinition } = useConfigContext((s) => s);
	const { classes } = useStyles();

	// every struct field in this case has the same ref
	const columns = structDef.fields[0].ref
		? getStructDefinition(structDef.fields[0].ref).fields.map((field) => field.visual)
		: [];

	return (
		<Stack className={`${className} ${classes.tableStack}`}>
			<Table fontSize={'sm'} className={classes.tableStyles} withColumnBorders striped>
				<thead>
					<tr>
						<th rowSpan={2}>{ROW_NAME}</th>
						{columns.map(({ label }) => (
							<th key={label}>{label}</th>
						))}
					</tr>
					<tr>
						{columns.map(({ tooltip }) => (
							<th className={classes.infoField} key={tooltip}>
								<span>{tooltip}</span>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{structDef.fields.map((struct) => {
						if (!struct.ref) return null;
						const primitiveFields = getStructDefinition(struct.ref).fields;

						return (
							<tr key={struct.visual.label}>
								<td>{struct.visual.label}</td>
								{primitiveFields.map((field) => {
									const name = `${segmentName}.${struct.name}.${field.name}`;

									return (
										<td key={name}>
											{field.type === 'boolean' ? (
												<CheckboxInput isReadOnly={isReadonly} name={name} />
											) : (
												<NumberInput
													isReadOnly={isReadonly}
													name={name}
													min={1}
													max={5}
													placeholder={'Enter value (1-5)'}
												/>
											)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Stack>
	);
};
