import React from 'react';

import { Box, createStyles, Stack, Text } from '@mantine/core';

interface Props extends React.PropsWithChildren {
	title: React.ReactNode;
	icon?: React.ReactNode;
	actions?: React.ReactNode;
}

const useStyles = createStyles(({ colors, radius }) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontWeight: 500,

		fontSize: '.85rem',
		padding: '.375rem .5rem',

		borderWidth: '1px',
		borderTopLeftRadius: radius.sm,
		borderTopRightRadius: radius.sm,
		borderStyle: 'solid',
		borderColor: colors.brand[1],
		backgroundColor: '#eef4ff',
	},

	title: {
		display: 'flex',
		alignItems: 'center',
	},

	content: {
		borderWidth: '0 1px 1px',
		borderStyle: 'solid',
		borderColor: colors.gray[2],
		padding: '.675rem',
		borderBottomLeftRadius: radius.sm,
		borderBottomRightRadius: radius.sm,
	},

	actions: {},
}));

const TFSection: React.FC<Props> = ({ children, title, icon, actions }) => {
	const { classes } = useStyles();
	return (
		<Box>
			<Box className={classes.header}>
				<Box className={classes.title}>
					{icon && (
						<Text h={18} mr={6} color="brand">
							{icon}
						</Text>
					)}
					<Text mt={1}>{title}</Text>
				</Box>

				<Box>{actions}</Box>
			</Box>

			<Stack spacing={9} className={classes.content}>
				{children}
			</Stack>
		</Box>
	);
};

export default TFSection;
