import { type NotificationProps, notifications } from '@mantine/notifications';

type PartialNotificationProps = Partial<NotificationProps>;

export class TFNotifier {
	static show(props: NotificationProps): void {
		if (props?.id) {
			notifications.hide(props.id);
		}

		notifications.show({
			...props,

			styles: {
				root: {
					height: 30,
					padding: '1.5rem .5rem 1.5rem 1.5rem',
				},
			},
		});
	}

	static success(message: string, props?: PartialNotificationProps): void {
		this.show({
			message,
			color: 'green',
			...props,
		});
	}

	static error(message: string, props?: PartialNotificationProps): void {
		this.show({
			message,
			color: 'red',
			...props,
		});
	}

	static info(message: string, props?: PartialNotificationProps): void {
		this.show({
			message,
			color: 'brand',
			...props,
		});
	}
}
