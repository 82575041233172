import { closeAllModals, openModal } from '@mantine/modals';

export class TFModals {
	static open({ children }: { children: React.ReactNode }): void {
		openModal({ children });
	}

	static closeAll(): void {
		closeAllModals();
	}
}
