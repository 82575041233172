import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Input, NumberInput, Select } from '@tf/ui';

import { useConfigContext } from '../../../hooks';
import type { DefaultInputProps } from '../types';

interface Money {
	amount: number | '';
	currency: string;
}

export default function MoneyInput({
	name,
	label,
	description,
	placeholder,
	isReadOnly,
	isRequired,
}: DefaultInputProps) {
	const form = useFormContext();

	const [value, setValue] = useState<Money>({
		amount: form.getValues(`${name}.amount`),
		currency: form.getValues(`${name}.currency`) || 'EUR',
	});

	useEffect(() => {
		const nextValue = {
			amount: value.amount,
			currency: value.currency,
		};
		form.setValue(name, nextValue, { shouldDirty: true, shouldTouch: true });
	}, [value]);

	const options = useConfigContext((s) => s.getEnumOptions('common__enum__currency')).map((option) => ({
		label: option.value,
		value: option.value,
	}));

	return (
		<Input.Wrapper id={name} label={label} required={isRequired}>
			<NumberInput
				id={name}
				value={value.amount}
				description={description}
				placeholder={placeholder || 'Enter amount...'}
				maxLength={13}
				readOnly={isReadOnly}
				error={!!form.getFieldState(`${name}.amount`).error}
				hideControls
				onChange={(amount) => setValue((state) => ({ ...state, amount }))}
				parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
				formatter={(value) => {
					if (value && !Number.isNaN(parseFloat(value))) {
						return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
					}
					return '';
				}}
				styles={{ rightSection: { width: 100 } }}
				rightSection={
					<Select
						id={`${name}-currency`}
						variant="filled"
						readOnly={isReadOnly}
						value={value.currency}
						onChange={(value: any) => setValue((state) => ({ ...state, currency: value }))}
						searchable
						styles={{
							root: { marginRight: '.5rem' },
							input: {
								height: 30,
								minHeight: 30,
								...(isReadOnly && {
									fontWeight: 500,
									textAlign: 'right',
									border: 'none',
								}),
							},
						}}
						data={options}
					/>
				}
			/>
		</Input.Wrapper>
	);
}
