import React from 'react';

import { Alert, createStyles, Group, Stack, TFText } from '@tf/ui';

import type { IdenfyJobResult } from '../../../../types';
import { IdenfyCheckResult } from '../../../../types';

import { DocumentsCheckIdenfyResult } from './DocumentsCheckIdenfyResult';
import { DocumentsCheckOnfidoResult } from './DocumentsCheckOnfidoResult';
import type { DocumentCheckJob, SubResult } from './types';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.brand[6],
	},
	clearSubStatusColor: {
		color: colors.green[8],
	},
	cautionSubStatusColor: {
		color: colors.yellow[7],
	},
	suspectedSubStatusColor: {
		color: colors.orange[7],
	},
	rejectedSubStatusColor: {
		color: colors.red[6],
	},
}));

const getJobResultMessage = (result: IdenfyCheckResult) => {
	switch (result) {
		case IdenfyCheckResult.APPROVED:
			return {
				status: 'APPROVED',
				text: 'There are no indications the document is fraudulent.',
				color: '#2F9E3F',
			};
		case IdenfyCheckResult.SUSPECTED:
			return {
				status: 'SUSPECTED',
				text: 'Document shows signs of suspect fraud.',
				color: '#FA5252',
			};
		case IdenfyCheckResult.DENIED:
			return {
				status: 'DENIED',
				text: "We can't process the document image, or the document isn't supported for processing.",
				color: '#FA5252',
			};
		case IdenfyCheckResult.EXPIRED:
		case IdenfyCheckResult.ACTIVE:
		default:
			return {
				status: 'UNIDENTIFIED',
				text: "We can't successfully complete all verifications.",
				color: '#344054',
			};
	}
};

const getSubResultText = (subResult: SubResult) => {
	switch (subResult) {
		case 'caution':
			return "We can't successfully complete all verifications, but this doesn’t necessarily point to a suspected document";
		case 'suspected':
			return 'Document shows signs of suspect fraud.';
		case 'rejected':
			return "We can't process the document image, or the document isn't supported for processing.";
		default:
			return 'There are no indications the document is fraudulent';
	}
};

interface Props {
	job: DocumentCheckJob;
}

export const DocumentsCheckGenericResult: React.FC<Props> = ({ job }) => {
	const { classes } = useStyles();

	const jobResult = (job.result as any).result as IdenfyJobResult;
	const idenfyStatus = jobResult?.status?.overall;

	if (job.payload.serviceKind === 'idenfy') {
		return (
			<Group className={classes.infoBlockWrapper}>
				<Stack spacing="xs">
					<TFText className={classes.infoBlockContent} weight={500}>
						Document check results:{' '}
						<span style={{ color: getJobResultMessage(idenfyStatus).color }}>
							{getJobResultMessage(idenfyStatus).status}
						</span>
					</TFText>
					<div style={{ color: getJobResultMessage(idenfyStatus).color }}>
						{getJobResultMessage(idenfyStatus).text}
					</div>
				</Stack>
				<DocumentsCheckIdenfyResult job={job} />
			</Group>
		);
	}

	// @ts-expect-error FIXME TS legacy jobkind, probably we'll remove it
	if (job.jobKind === 'ONFIDO_CHECK') {
		const report = (job?.result as any)?.reports[0];
		return (
			<Group className={classes.infoBlockWrapper}>
				<Stack spacing="xs">
					<TFText className={classes.infoBlockContent} weight={500}>
						Document check result:&nbsp;
						<span className={classes[`${report?.subResult as SubResult}SubStatusColor`]}>
							{(job?.result as any)?.reports[0]?.subResult.toUpperCase()}
						</span>
					</TFText>
					<TFText
						className={classes[`${report?.subResult as SubResult}SubStatusColor`]}
						lineClamp={3}
						size="sm"
					>
						{getSubResultText(report?.subResult)}
					</TFText>
				</Stack>
				<DocumentsCheckOnfidoResult report={report} />
			</Group>
		);
	}

	return (
		<Alert w="100%" color="red">
			Unknown service provider
		</Alert>
	);
};
