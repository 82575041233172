import React, { useLayoutEffect } from 'react';

import { Box, Center, createStyles, Stack, Title } from '@mantine/core';
import { useDebouncedValue, useLocalStorage } from '@mantine/hooks';
import type {
	MRT_ColumnDef,
	MRT_ColumnFiltersState,
	MRT_ColumnOrderState,
	MRT_ColumnSizingState,
	MRT_PaginationState,
	MRT_SortingState,
	MRT_TableOptions,
	MRT_Updater,
	MRT_VisibilityState,
} from 'mantine-react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import { TFCard } from '../index';
import type { FilterItem } from '../TFDataFilters';
import { ActiveFilters, FilterButton, SearchInput } from '../TFDataFilters';

import { VisibilityButton } from './VisibilityButton';

const useStyles = createStyles(() => ({
	sorter: {
		display: 'flex',
		alignItems: 'center',
		padding: '0.45rem 0.7rem',
		userSelect: 'none',
		transition: '.05s ease-in-out',
	},
	searchBar: {
		padding: '.75rem',
		display: 'flex',
	},
}));

interface Props<TData extends Record<string, any>> {
	name: string;
	data: TData[];
	defs: MRT_ColumnDef<TData>[];
	hideSearchbar?: boolean;
	availableFilters?: FilterItem[];
	tableProps?: MRT_TableOptions['mantineTableProps'];
	paperProps?: MRT_TableOptions['mantinePaperProps'];
	isLoading?: boolean;
	serverPagination?: {
		pageSize: number;
		pageIndex: number;
		rowCount: number;
		onPaginationChange: (state: MRT_Updater<MRT_PaginationState>) => void;
	};
	serverSorting?: {
		state: MRT_SortingState;
		onSortingChange: (state: MRT_Updater<MRT_SortingState>) => void;
	};
	serverFiltering?: {
		state: MRT_ColumnFiltersState;
		onFiltersChange: (state: MRT_Updater<MRT_ColumnFiltersState>) => void;
	};
	serverSearch?: {
		state: string;
		onSearchChange: (state: string) => void;
	};
	noDataText?: React.ReactElement;
}

export function TFDataTable<T extends Record<string, any>>({
	name,
	data,
	defs,
	hideSearchbar,
	availableFilters = [],
	tableProps,
	paperProps,
	isLoading,
	serverPagination,
	serverSorting,
	serverFiltering,
	serverSearch,
	noDataText,
}: Props<T>) {
	const { classes } = useStyles();

	const nameToKeyMapping: Record<any, string> = {};
	const initialVisibilityState: Record<any, boolean> = {};
	const initialOrderState: string[] = [];

	defs.forEach((column) => {
		const key = column.id ?? column.accessorKey;
		if (key === undefined) {
			throw new Error('no column id or accessorKey');
		}

		nameToKeyMapping[key] = column.header;
		initialVisibilityState[key] = true;
		initialOrderState.push(key as string);
	});

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: `${name}-searchValue`,
	});

	const [debouncedValue] = useDebouncedValue(searchValue, 200);

	const [filters, setFilters] = useLocalStorage<MRT_ColumnFiltersState>({
		defaultValue: [],
		key: `${name}-filters`,
	});

	const [sorting, setSorting] = useLocalStorage<MRT_SortingState>({
		defaultValue: [],
		key: `${name}-sorting`,
	});

	// For some reason, the sorting state resets to default on every initial table render. This trick helps to maintain persistent sorting
	const sortState = JSON.parse(localStorage.getItem(`${name}-sorting`) || '[]');
	useLayoutEffect(() => {
		if (sortState.length > 0) {
			setSorting(sortState);
		}
	}, []);

	const [order, setOrder] = useLocalStorage<MRT_ColumnOrderState>({
		defaultValue: initialOrderState,
		key: `${name}-order`,
	});

	const [sizing, setSizing] = useLocalStorage<MRT_ColumnSizingState>({
		defaultValue: {},
		key: `${name}-sizing`,
	});

	const [visibility, setVisibility] = useLocalStorage<MRT_VisibilityState>({
		defaultValue: initialVisibilityState,
		key: `${name}-visibility`,
	});

	const table = useMantineReactTable({
		columns: defs,
		data,
		onColumnOrderChange: setOrder,
		onColumnVisibilityChange: setVisibility,
		onColumnSizingChange: setSizing,
		onSortingChange: serverSorting?.onSortingChange || setSorting,
		onPaginationChange: serverPagination?.onPaginationChange,

		state: {
			sorting: serverSorting?.state?.length
				? [{ id: serverSorting.state[0].id, desc: serverSorting.state[0].desc }]
				: sorting,
			globalFilter: serverSearch ? undefined : debouncedValue,
			columnFilters: filters,
			columnOrder: order,
			columnVisibility: visibility,
			columnSizing: sizing,
			isLoading,
			pagination: serverPagination
				? { pageSize: serverPagination.pageSize, pageIndex: serverPagination.pageIndex }
				: { pageSize: data.length, pageIndex: 0 },
		},
		layoutMode: 'grid',
		enableColumnOrdering: true,
		enableColumnResizing: true,
		enableSorting: true,
		columnFilterDisplayMode: 'custom',
		enableBottomToolbar: Boolean(serverPagination),
		manualPagination: Boolean(serverPagination),
		manualSorting: Boolean(serverSorting),
		paginationDisplayMode: 'pages',
		enableTopToolbar: false,
		enableColumnActions: false,
		rowCount: serverPagination?.rowCount,

		mantineTableProps: {
			verticalSpacing: 5,
			fontSize: 13,
			striped: true,
			...tableProps,
		},
		mantineLoadingOverlayProps: {
			zIndex: 300,
		},
		mantinePaperProps: {
			withBorder: false,
			sx: { borderRadius: '8px', boxShadow: 'none' },
			...paperProps,
		},
		mantineTableHeadCellProps: {
			sx: {
				'[class*="Indicator"]': {
					marginLeft: '4px',
				},
				'[class*="Divider"]': {
					borderWidth: '1px',
					margin: '0 2px',
					opacity: '0.7',
				},
			},
		},
		mantineBottomToolbarProps: {
			sx: {
				'[class*="Select-wrapper"]': {
					width: '80px',
				},
			},
		},
	});

	const { rows } = table.getRowModel();

	const hasVisibleColumns = Object.values(visibility).some(Boolean);

	return (
		<>
			{!hideSearchbar && (
				<Box mb=".75rem">
					<TFCard>
						<Box className={classes.searchBar}>
							<SearchInput
								autoFocus
								searchValue={serverSearch?.state || searchValue}
								onSearchChange={serverSearch?.onSearchChange || setSearchValue}
							/>
							<VisibilityButton
								visibilityOptions={visibility}
								onVisibilityChange={setVisibility}
								nameToKeyMapping={nameToKeyMapping}
							/>
							<FilterButton
								availableFilters={availableFilters}
								activeFilters={serverFiltering?.state || filters}
								setFilters={serverFiltering?.onFiltersChange || setFilters}
							/>
						</Box>
						<ActiveFilters
							availableFilters={availableFilters}
							activeFilters={serverFiltering?.state || filters}
							setFilters={serverFiltering?.onFiltersChange || setFilters}
						/>
					</TFCard>
				</Box>
			)}

			<TFCard>
				{rows.length > 0 && hasVisibleColumns ? (
					<MantineReactTable table={table} />
				) : (
					<Center h={300}>
						<Stack align="center" spacing="8px">
							<Title size="20px" w="bold" order={4}>
								No data
							</Title>
							{noDataText}
						</Stack>
					</Center>
				)}
			</TFCard>
		</>
	);
}
