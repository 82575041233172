import React from 'react';

import { Box, Input, Text } from '@mantine/core';

interface Props {
	value: string | string[];
	label?: string;
}

const TFField: React.FC<Props> = ({ value, label }) => {
	return (
		<Box>
			{label && <Input.Label sx={{ cursor: 'default', userSelect: 'none' }}>{label}</Input.Label>}
			<Box
				sx={({ colors }) => ({
					display: 'flex',
					alignItems: 'center',
					minHeight: '2.25rem',
					padding: '0 .75rem',
					backgroundColor: colors.gray[0],
					borderBottomStyle: 'solid',
					borderBottomWidth: 1,
					borderBottomColor: colors.gray[2],
				})}
			>
				<Text size=".8rem" color="dimmed">
					{Array.isArray(value) ? value.join(', ') : value}
				</Text>
			</Box>
		</Box>
	);
};

export default TFField;
