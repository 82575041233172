import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Box, createStyles, Input, Stack, TextInput } from '@tf/ui';
import type { CorporateBasicAccountData } from '@tf/utils';

import { SearchButtons } from './index';

const useStyles = createStyles(({ radius }) => ({
	root: {
		borderRadius: radius.sm,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'default',
		justifyContent: 'space-between',
		backgroundColor: '#eef4ff',
	},
	title: {
		fontWeight: 500,
		display: 'flex',
	},
	body: {},
	textInput: {},
}));

interface Props {
	initialValue?: CorporateBasicAccountData;
	submit: (data: CorporateBasicAccountData) => void;
	close: () => void;
	isReadOnly: boolean;
}

export const CorporateSearchForm: React.FC<Props> = ({ initialValue, submit, close, isReadOnly }) => {
	const { classes } = useStyles();

	const { control, handleSubmit, formState } = useForm<{ entity_name: string }>({
		defaultValues: { entity_name: initialValue?.entity_name ?? '' },
		mode: 'onSubmit',
	});

	const onSubmit = handleSubmit((data) => {
		if (Object.values(formState.errors).length === 0) {
			submit(data);
			close();
		}
	});

	return (
		<Box className={classes.root}>
			<Stack spacing={12} className={classes.body}>
				<Controller
					name="entity_name"
					control={control}
					rules={{ required: true }}
					render={({ field, fieldState }) => {
						return (
							<Input.Wrapper label="Entity name" required labelProps={{ required: true }}>
								<TextInput disabled={isReadOnly} error={Boolean(fieldState.error)} {...field} />
							</Input.Wrapper>
						);
					}}
				/>
				<SearchButtons isReadOnly={isReadOnly} close={close} submit={onSubmit} />
			</Stack>
		</Box>
	);
};
