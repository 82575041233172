import React from 'react';

import { observer, useObservable } from '@datagrid/state';

import type { SearchHit } from '@tf/shared';
import { getResultHit, JobSearchResultCard } from '@tf/shared';
import { Box, createStyles, Group, Modal, Stack, TFText } from '@tf/ui';

const useStyles = createStyles(({ colors, radius, fn }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
		cursor: 'pointer',
	},
	infoBlockToggleWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: fn.lighten(colors.brand[1], 0.5),
		},
	},
	infoBlockContent: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		color: colors.dark[4],
	},
	infoBlockText: {
		maxWidth: '500px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	chevron: {
		color: colors.brand[6],
		padding: '.05rem',
		borderRadius: '50%',
	},
	chevronWrapper: {
		fontWeight: 600,
	},
}));

interface ComponentState {
	isOpen: boolean;
}

interface Props {
	hit?: SearchHit;
}

export const JobResultCard: React.FC<Props> = observer(({ hit }) => {
	const { classes } = useStyles();

	const state = useObservable<ComponentState>({
		isOpen: false,
	});

	if (!hit) return null;

	return (
		<Stack spacing="md">
			<Box className={classes.infoBlockToggleWrapper} onClick={state.isOpen.toggle}>
				<Group position="apart" className={classes.infoBlockContent}>
					<TFText size="sm" className={classes.infoBlockText}>
						Match: {hit?.doc?.name}
					</TFText>
					<Group className={classes.chevronWrapper}>
						<TFText
							size="sm"
							sx={({ colors }) => ({
								color: colors.brand[7],
							})}
						>
							{state.isOpen.get() ? 'Hide' : 'Show'} details
						</TFText>
					</Group>
				</Group>
			</Box>
			<Modal size="xl" opened={state.isOpen.get()} title={hit?.doc?.name} onClose={state.isOpen.toggle}>
				<Box p="0.75rem">
					<JobSearchResultCard withInnerCard={false} hit={getResultHit(hit)} />
				</Box>
			</Modal>
		</Stack>
	);
});
