import React from 'react';

import {
	Box,
	Button,
	createStyles,
	Flex,
	Group,
	Icon,
	Modal,
	ModalFooter,
	Popover,
	Stack,
	TFText,
	ThemeIcon,
	useDisclosure,
} from '@tf/ui';
import { S } from '@tf/utils';

import type { OnFidoReport, OnFidoRowResult } from './types';

interface Props {
	report?: OnFidoReport;
}

const useStyles = createStyles(({ colorScheme, colors, fn, radius }) => ({
	icon: {
		color: colorScheme === 'dark' ? colors.dark[7] : colors.light[1],
		cursor: 'pointer',
	},
	rowWrapper: {
		cursor: 'pointer',
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		padding: '.75rem .75rem',
		justifyContent: 'space-between',
		borderRadius: radius.sm,
		backgroundColor: colorScheme === 'dark' ? colors.dark[6] : fn.lighten(colors.gray[1], 0.5),
		'&:hover': {
			backgroundColor: colorScheme === 'dark' ? colors.dark[4] : colors.gray[1],
		},
	},
	breakdownTitle: {
		':not(:first-of-type)': {
			marginTop: '1.5rem',
			paddingBottom: '0',
		},
	},
	rowContent: {
		maxWidth: '300px',
	},
	infoIcon: {
		color: colorScheme === 'dark' ? colors.dark[9] : colors.gray[3],
	},
	clearIcon: {
		backgroundColor: colors.green[6],
	},
	considerIcon: {
		backgroundColor: colors.yellow[7],
	},
	unidentifiedIcon: {
		backgroundColor: colors.red[6],
	},
	clearTooltip: {
		color: colors.green[7],
	},
	considerTooltip: {
		color: colors.yellow[7],
	},
	unidentifiedTooltip: {
		color: colors.red[6],
	},
}));

type Result = 'clear' | 'consider' | 'unidentified';

const resultDescription: Record<OnFidoRowResult, string> = {
	clear: 'CLEAR: There are no indications is fraudulent',
	consider: "CONSIDER: We can't successfully complete verifications this value",
	unidentified: "UNIDENTIFIED: We can't identify the values",
};

export const DocumentsCheckOnfidoResult: React.FC<Props> = ({ report }) => {
	const { classes } = useStyles();

	const getIcon = (result: string) => {
		switch (result) {
			case 'clear':
				return <Icon.IconCircleCheck size="1rem" />;
			case 'consider':
				return <Icon.IconExclamationCircle size="1rem" />;
			case 'unidentified':
				return <Icon.IconCircleX size="1rem" />;
		}
	};

	const { toggle, isOpen } = useDisclosure();

	if (!report) return null;

	return (
		<>
			<Flex justify="end">
				<Button
					size="sm"
					variant="subtle"
					onClick={toggle}
					leftIcon={<Icon.IconFileInfo size={18} stroke={1.5} />}
				>
					Details
				</Button>
			</Flex>
			<Modal size="600px" title="Details" opened={isOpen} onClose={toggle}>
				<Box p="0.75rem">
					{report?.breakdown.map((breakdown) => {
						return (
							<Stack className={classes.breakdownTitle} key={breakdown.name}>
								<TFText weight={500} size="lg">
									{S.prettify(breakdown.name)}
								</TFText>
								{breakdown.breakdown?.map((item) => (
									<Popover position="bottom-start" withArrow shadow="md" key={item.name}>
										<Popover.Target>
											<Group spacing="sm" className={classes.rowWrapper}>
												<Group spacing="sm">
													<ThemeIcon
														classNames={[classes.icon, classes[`${item?.result as Result}Icon`]]}
														color="teal"
														size={19}
														radius="xl"
													>
														{getIcon(item?.result)}
													</ThemeIcon>
													<TFText size="sm" className={classes.rowContent} lineClamp={3}>
														{S.prettify(item?.name)}
													</TFText>
												</Group>
												<Icon.IconInfoOctagon size="1rem" className={classes.infoIcon} />
											</Group>
										</Popover.Target>
										<Popover.Dropdown>
											<TFText size="sm" className={classes[`${item?.result as Result}Tooltip`]}>
												{resultDescription[item?.result]}
											</TFText>
										</Popover.Dropdown>
									</Popover>
								))}
							</Stack>
						);
					})}
				</Box>
				<ModalFooter submitLabel="Close" onSubmit={toggle} />
			</Modal>
		</>
	);
};
