import React from 'react';

import { Anchor, TFText } from '@tf/ui';

import { TFErrorTemplate } from './TFErrorTemplate';

export interface TFGlobalErrorBoundaryProps {
	email?: string;
}

export const TFGlobalErrorBoundary: React.FC<TFGlobalErrorBoundaryProps> = ({ email }) => {
	return (
		<TFErrorTemplate
			title="Initialisation Error"
			text="An error occurred during initialisation of the application."
		>
			<TFText size=".85rem" color="dimmed">
				If it repeats, please email us {email ? 'at' : ''}{' '}
				<Anchor href={`mailto:${email}`}>{email}</Anchor>
			</TFText>
		</TFErrorTemplate>
	);
};
