import React, { useEffect, useState } from 'react';

import { Alert, Box, createStyles, Flex, Icon, Modal, ScrollArea, Select, TFText } from '@tf/ui';
import { fmt, S } from '@tf/utils';

import type { IdenfyJobResult } from '../../types';
import { IdenfyCheckResult } from '../../types';
import type { DocumentCheckJob } from '../FileInput/extensions/DocumentsCheck/types';

import { ImageDisplay } from './ImageDisplay';

const useStyles = createStyles(({ colors, fontSizes }) => ({
	tag: {
		backgroundColor: colors.red[5],
		color: 'white',
		padding: '0 0.5rem',
		marginRight: '0.5rem',
		borderRadius: '2px',
		fontSize: '.9rem',
	},
	itemTitle: {
		color: colors.dark[7],
		fontWeight: 600,
		fontSize: fontSizes.s,
	},
	itemText: {
		color: colors.gray[7],
	},
	modalAlert: {
		'& .trustform-Alert-title': {
			color: colors.dark[5],
		},
	},
}));

interface Props {
	job: DocumentCheckJob;
	isOpen: boolean;
	onModalClose: () => void;
	imageUrls: { label: string; value: string }[];
}

export const VerificationResultModal: React.FC<Props> = ({ job, isOpen, onModalClose, imageUrls }) => {
	const { classes } = useStyles();
	const [selectedUrl, setSelectedUrl] = useState<string | null>(null);

	const jobResult = (job?.result as any)?.result as IdenfyJobResult;
	const generalResult = jobResult?.status;
	const jobResultStatus = jobResult.status?.overall;

	const suspicionReasons = generalResult?.suspicionReasons ?? [];
	const mismatchTags = generalResult?.mismatchTags ?? [];
	const denyReasons = generalResult?.denyReasons ?? [];
	const fraudTags = generalResult?.fraudTags ?? [];

	useEffect(() => {
		if (!imageUrls.length) return;
		setSelectedUrl(imageUrls[0].value);
	}, [imageUrls]);

	return (
		<Modal
			size="xl"
			scrollAreaComponent={ScrollArea.Autosize}
			opened={isOpen}
			onClose={onModalClose}
			title="Verification result"
			withinPortal
		>
			<Box p={'1rem'}>
				<Alert
					title={getIdenfyCheckResultMessage(jobResultStatus).label}
					icon={getIdenfyCheckResultMessage(jobResultStatus).icon}
					className={classes.modalAlert}
					color="dark"
					sx={{
						backgroundColor: `rgba(${getIdenfyCheckResultMessage(jobResultStatus).baseColor}, 0.2)`,
						borderColor: `rgba(${getIdenfyCheckResultMessage(jobResultStatus).baseColor}, 0.5)`,
						color: `rgb(${getIdenfyCheckResultMessage(jobResultStatus).baseColor})`,
					}}
				>
					{getIdenfyCheckResultMessage(jobResultStatus).description}
				</Alert>
				<Flex p={'1rem 0'}>
					<Flex direction={'column'} sx={{ width: '25%' }}>
						{renderItem('Auto face match', generalResult?.autoFace, S.prettify)}
						{renderItem('Manual face match', generalResult?.manualFace, S.prettify)}
						{renderItem('Manual document match', generalResult?.manualDocument, S.prettify)}
						{renderItem('Auto document match', generalResult?.autoDocument, S.prettify)}
						{renderItem('Platform', jobResult?.platform, S.prettify)}
						{renderItem('Client location', jobResult?.clientLocation, (v) => v)}
						{renderItem('Client IP country', jobResult?.clientIpCountry, (v) => v)}
						{renderItem('Client IP', jobResult?.clientIp, (v) => v)}
						{renderItem('Start time', jobResult?.startTime, (v) =>
							fmt.toDate(Number(v) * 1000, { preset: 'full_date_with_time' })
						)}
						{renderItem('Finish time', jobResult?.finishTime, (v) =>
							fmt.toDate(Number(v) * 1000, { preset: 'full_date_with_time' })
						)}
					</Flex>
					<Flex direction={'column'} sx={{ width: '75%' }}>
						<Select label={'Files'} value={selectedUrl} data={imageUrls} onChange={setSelectedUrl} />
						<ImageDisplay storageKey={selectedUrl} />
						{fraudTags.length > 0 && (
							<>
								<TFText weight={500} size="md" mb="0.25rem">
									Fraud tags
								</TFText>
								<Flex mb=".75rem" wrap="wrap">
									{fraudTags.map((tag: string) => (
										<div className={classes.tag} key={tag}>
											{S.prettify(tag)}
										</div>
									))}
								</Flex>
							</>
						)}
						{suspicionReasons.length > 0 && (
							<>
								<TFText weight={500} size="md" mb="0.25rem">
									Suspicion reasons
								</TFText>
								<Flex mb=".75rem" wrap="wrap">
									{suspicionReasons.map((tag: string) => (
										<div className={classes.tag} key={tag}>
											{S.prettify(tag)}
										</div>
									))}
								</Flex>
							</>
						)}
						{mismatchTags.length > 0 && (
							<>
								<TFText weight={500} size="md" mb="0.25rem">
									Mismatch tags
								</TFText>
								<Flex mb=".75rem" wrap="wrap">
									{mismatchTags.map((tag: string) => (
										<div className={classes.tag} key={tag}>
											{S.prettify(tag)}
										</div>
									))}
								</Flex>
							</>
						)}
						{denyReasons.length > 0 && (
							<>
								<TFText weight={500} size="md" mb="0.25rem">
									Deny reasons
								</TFText>
								<Flex mb=".75rem" wrap="wrap">
									{denyReasons.map((tag: string) => (
										<div className={classes.tag} key={tag}>
											{S.prettify(tag)}
										</div>
									))}
								</Flex>
							</>
						)}
					</Flex>
				</Flex>
			</Box>
		</Modal>
	);
};

function renderItem<Value>(
	label: string,
	value: Value | null | undefined,
	formatValue: (value: Value) => string
) {
	if (!value) {
		return null;
	}

	return <Item label={label} text={formatValue(value)} />;
}

const Item: React.FC<{ label: string; text: string }> = ({ label, text }) => {
	const { classes } = useStyles();

	return (
		<Box pb={'0.6rem'}>
			<TFText className={classes.itemTitle}>{label}</TFText>
			<TFText className={classes.itemText}>{text}</TFText>
		</Box>
	);
};

export const getIdenfyCheckResultMessage = (result: IdenfyCheckResult) => {
	switch (result) {
		case IdenfyCheckResult.APPROVED:
			return {
				label: 'Verified',
				description: 'Identity has been successfully verified.',
				baseColor: '64, 192, 87',
				icon: <Icon.IconCircleCheck />,
			};
		case IdenfyCheckResult.DENIED:
			return {
				label: 'Failed',
				description: ' Identity verification has failed.',
				baseColor: '255, 107, 107',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.SUSPECTED:
			return {
				label: 'Suspicious',
				description:
					'Digital identity check has detected suspicious activity. Manual review may be required.',
				baseColor: '246, 102, 6',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.REVIEWING:
			return {
				label: 'Under review',
				description: 'Identity verification is under manual review.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.ACTIVE:
			return {
				label: 'Verification token active',
				description: 'The token used for identity verification is still active.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.EXPIRED:
			return {
				label: 'Verification token expired',
				description: 'The token used for identity verification has expired.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.EXPIRED_DELETED:
			return {
				label: 'Verification token expired and deleted',
				description: 'Identity verification record has been deleted.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.DELETED:
			return {
				label: 'Deleted',
				description: 'Identity verification record has been deleted.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		case IdenfyCheckResult.ARCHIVED:
			return {
				label: 'Archived',
				description: 'Identity verification record has been archived.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
		default:
			return {
				status: 'UNIDENTIFIED',
				description: 'The verification status is not recognized.',
				baseColor: '82, 139, 255',
				icon: <Icon.IconAlertCircle />,
			};
	}
};
