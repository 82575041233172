import React, { useState } from 'react';

import { Box, Button, Checkbox, Indicator, Popover, Stack } from '@mantine/core';
import type { MRT_VisibilityState } from 'mantine-react-table';

import { Icon, TFText } from '../../../index';

interface VisibilityButtonProps {
	nameToKeyMapping: Record<string, string>;
	visibilityOptions: MRT_VisibilityState;
	onVisibilityChange: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>;
}

export const VisibilityButton = ({
	onVisibilityChange,
	visibilityOptions,
	nameToKeyMapping,
}: VisibilityButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const handleClearAll = () => {
		onVisibilityChange(
			Object.keys(nameToKeyMapping).reduce<Record<string, boolean>>((acc, curr) => {
				acc[curr] = true;
				return acc;
			}, {})
		);
		setIsOpen(false);
	};

	const hiddenColumnsCount = Object.values(visibilityOptions).filter((opt) => !opt).length;

	return (
		<Popover
			width={250}
			opened={isOpen}
			onClose={() => setIsOpen(false)}
			transitionProps={{ exitDuration: 0 }}
			trapFocus
			position={'bottom-end'}
			withArrow
			shadow="md"
		>
			<Popover.Target>
				<Indicator
					disabled={!hiddenColumnsCount}
					color="red"
					size={20}
					processing
					label={hiddenColumnsCount}
				>
					<Button ml="9px" onClick={toggle} variant="light" p="8px">
						<Icon.IconAdjustmentsHorizontal size="16px" />
						<TFText size="14px" ml="4px">
							Edit table
						</TFText>
					</Button>
				</Indicator>
			</Popover.Target>

			<Popover.Dropdown
				sx={(theme) => ({
					background: theme.white,
				})}
			>
				<TFText size="md" color="black" weight={500} mb=".5rem">
					Change columns visibility
				</TFText>
				<Stack spacing=".3rem">
					{Object.entries(visibilityOptions).map(([key, value]) => (
						<Checkbox
							key={key}
							sx={{ margin: '0.3rem 0' }}
							checked={value}
							onChange={(e) => onVisibilityChange({ ...visibilityOptions, [key]: e.target.checked })}
							label={<span>{nameToKeyMapping[key]}</span>}
						/>
					))}
				</Stack>
				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button fullWidth variant="light" size="sm" onClick={handleClearAll}>
						Show all
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
