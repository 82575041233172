import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, createStyles, Group, Stack, TFText } from '@tf/ui';

import { JobResultComment } from './index';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.light[3],
	},
}));

interface Props {
	comments: BackendTypes.AdhocComment[];
}

export const JobResultCommentsView: React.FC<Props> = ({ comments }) => {
	const { classes } = useStyles();

	return (
		<Stack spacing="md">
			<Box className={classes.infoBlockWrapper}>
				<Group className={classes.infoBlockContent}>
					<TFText size="sm" lineClamp={1}>
						No matches were selected for this account
					</TFText>
				</Group>
			</Box>
			{comments?.length
				? comments.map((comment, index) => (
						<JobResultComment
							key={index}
							comment={comment.comment}
							date={comment.date}
							username={comment.user as string}
						/>
				  ))
				: null}
		</Stack>
	);
};
